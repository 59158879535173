import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'intersection-observer';
import loadable from '@loadable/component';

import './Styles/style.scss';

function App() {
	const Home = loadable(() => import('./Pages/Home'));
	const Imprint = loadable(() => import('./Pages/Imprint'));
	const Privacy = loadable(() => import('./Pages/Privacy'));
	const FourZeroFour = loadable(() => import('./Pages/FourZeroFour'));
	const Nutzen = loadable(() => import('./Pages/Nutzen'));
	const Zielgruppe = loadable(() => import('./Pages/Zielgruppe'));
	const Story = loadable(() => import('./Pages/Story'));

	const onMount = async () => {
		// Do nothing
	};

	// On Component mount (empty array means, only once fired)
	useEffect(() => {
		onMount();
	}, []);

	return (
		<Router>
			<Switch>
				<Route exact path={['/', '/fuehrung-konkret']} component={Home} />
				<Route exact path={['/imprint']} component={Imprint} />
				<Route exact path={['/privacy']} component={Privacy} />
				<Route exact path={['/fuehrung-konkret/nutzen']} component={Nutzen} />
				<Route exact path={['/fuehrung-konkret/zielgruppe']} component={Zielgruppe} />
				<Route exact path={['/fuehrung-konkret/story']} component={Story} />
				<Route component={FourZeroFour} />
			</Switch>
		</Router>
	);
}

export default App;
